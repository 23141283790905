import React from 'react'
import { ImgWebp } from 'src/styles/imgWebp'

import { WIDTH_MD } from 'src/styles/breakpoints'

import Profitability from '@interco/icons/build-v4/orangeds/MD/profitability'
import Wallet from '@interco/icons/build-v4/orangeds/MD/wallet'
import LikeOutline from '@interco/icons/build-v4/orangeds/MD/like-outline'
import Withdraw from '@interco/icons/build-v4/orangeds/MD/withdraw'

import * as S from './styles'

const ComoFuncionaOPronampeNoInter = () => {
  const [ isMobile, setIsMobile ] = React.useState(false)

  React.useEffect(() => {
    if (window.innerWidth < WIDTH_MD) {
      setIsMobile(true)
    }
  }, [])

  return (
    <S.Section id='como-funciona-o-pronampe-no-inter'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <S.MainCard className='col-12 col-md-6 col-lg-5 offset-lg-1 order-md-last'>
            <h2 className='fs-28 lh-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-lg-52 fw-500 font-sora text-grayscale--500'>
              Como funciona <span className='d-block d-md-inline d-lg-block d-xl-inline'>o Pronampe no</span> Inter?
            </h2>
            {isMobile && (
              <ImgWebp
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/pronampe-dobra3/image.webp'
                alt='Home do Pronampe no App Inter Empresas'
                breakPointsSize={
                {
                  sm: '262px',
                  md: '300px',
                  lg: '436px',
                  xl: '526px',
                }
              }
              />
            )}
            <div>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--500'>As melhores condições para levar seu negócio além estão aqui</p>
              <div>
                <div className='d-flex align-items-center mb-4 mb-md-3'>
                  <div>
                    <Profitability height={24} width={24} color='#161616' />
                  </div>
                  <h3 className='fs-12 lh-15 fs-md-10 lh-md-12 fs-lg-12 lh-lg-14 fw-400 text-grayscale--500 mb-0 ml-3'>Garanta até <span className='fw-700'>60 meses</span> de prazo de pagamento</h3>
                </div>
                <div className='d-flex align-items-center mb-4 mb-md-3'>
                  <div>
                    <Wallet height={24} width={24} color='#161616' />
                  </div>
                  <h3 className='fs-12 lh-15 fs-md-10 lh-md-12 fs-lg-12 lh-lg-14 fw-400 text-grayscale--500 mb-0 ml-3'>Aproveite <span className='fw-700'>carência total de 3 meses</span></h3>
                </div>
                <div className='d-flex align-items-center mb-4 mb-md-3'>
                  <div>
                    <LikeOutline height={24} width={24} color='#161616' />
                  </div>
                  <h3 className='fs-12 lh-15 fs-md-10 lh-md-12 fs-lg-12 lh-lg-14 fw-400 text-grayscale--500 mb-0 ml-3'>Conte com contratação <span className='fw-700'>100% digital</span></h3>
                </div>
                <div className='d-flex align-items-center'>
                  <div>
                    <Withdraw height={24} width={24} color='#161616' />
                  </div>
                  <h3 className='fs-12 lh-15 fs-md-10 lh-md-12 fs-lg-12 lh-lg-14 fw-400 text-grayscale--500 mb-0 ml-3'>Tenha a opção de <span className='fw-700'>contratação com seguro</span></h3>
                </div>
              </div>
            </div>
          </S.MainCard>
          {!isMobile && (
            <div className='col-12 col-md-6'>
              <ImgWebp
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/pronampe-dobra3/image.webp'
                alt='Home do Pronampe no App Inter Empresas'
                breakPointsSize={
                {
                  sm: '100%',
                  md: '300px',
                  lg: '436px',
                  xl: '526px',
                }
                }
              />
            </div>
          )}
        </div>
      </div>
    </S.Section>
  )
}

export default ComoFuncionaOPronampeNoInter
