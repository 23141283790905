import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { gray } from 'src/styles/newColors'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${gray};
  padding: 3rem 0;

  .btn {
    margin-top: 2rem;

    @media ${device.tablet} {
      width: 200px;
      height: 48px;
    }

    @media ${device.desktopLG} {
      width: 296px;
    }

    @media ${device.desktopXL} {
      width: 460px;
    }
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;

  .step {
    background-color: white;
    display: flex;
    align-items: center;
    border-radius: 16px;
    padding: 24px;
    gap: 24px;
    height: 116px;

    @media ${device.tablet} {
      flex-basis: 294px;
    }

    @media ${device.desktopLG} {
      flex-basis: 392px;
    }

    @media ${device.desktopXL} {
      flex-basis: 552px;
    }

    .step-number {
      width: 24px;
      height: 24px;
      background-color: ${orange.extra};
      border-radius: 16px;
      
      display: flex;
      justify-content: center;
      align-items: center;

      @media ${device.tablet} {
        width: 34px;
        height: 34px;
      }

      @media ${device.desktopXL} {
        border-radius: 24px;
        width: 40px;
        height: 40px;
      }

      span {
        color: white;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
`
