import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import * as S from './styles'

const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <S.Section className='d-md-flex align-items-md-center' role='img' aria-label='Empreendedor, segurando um tablet e usando um avental, sorrindo'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h1 className='fs-24 lh-28 fs-lg-32 lh-lg-40 fs-xl-56 lh-xl-60 fw-500 text-white mb-3 mb-lg-4'>
              <span className='d-block fw-700'>
                Pronampe e{' '}
                <span className='d-block'>Inter Empresas:</span>
              </span>
              o sucesso do seu <span className='d-lg-block'>negócio começa aqui</span>
            </h1>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-white mb-0'>
              Fortaleça seu negócio com o parceiro certo: garanta crédito para sua empresa com pagamento em até 60x.
            </p>
            <a
              href='https://conta-digital-pj.inter.co/login'
              className='btn btn--lg bg-orange--extra text-white text-none mw-100'
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click button',
                  element_name: 'Abrir conta PJ gratuita',
                  section_name: 'Pronampe e Inter Empresas: o sucesso do seu negócio começa aqui',
                  redirect_url: 'https://conta-digital-pj.inter.co/login',
                })
              }}
            >
              Abrir conta PJ gratuita
            </a>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero
