import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/newColors'

export const Section = styled.section`
  background-color: ${orange.light};
  padding: 3rem 0;

  @media ${device.desktopXL} {
    padding: 8rem 0;
  }
`

export const MainCard = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;

  padding: 48px 32px;
  border-radius: 32px;
  background-color: white;

  @media ${device.tablet} {
    align-items: start;
    padding: 16px 24px;
    gap: 16px;
  }

  @media ${device.desktopLG} {
    padding: 32px 24px;
    gap: 24px;
  }

  @media ${device.desktopXL} {
  padding: 48px 32px;
  }
`
