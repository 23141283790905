import React from 'react'
import * as S from './styles'
import { ImgWebp } from 'src/styles/imgWebp'

const OQueEOPronampe = () => {
  return (
    <S.Section>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 order-md-last'>
            <h2 className='fs-24 lh-28 fs-md-28 lh-md-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-lg-52 fw-500 text-grayscale--500 mb-4'>O que é o Pronampe?</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400'>
              O <span className='fw-700'>Programa Nacional de Apoio às Microempresas e Empresas de Pequeno Porte (Pronampe)</span> é uma linha de capital de giro desenvolvida pelo Governo Federal para ajudar a gerar oportunidades de crescimento do seu negócio.
              <br /><br />
              Com contratação 100% digital, prazos de pagamento maiores e taxas mais atrativas, essa é a chance de fortalecer sua empresa com mais facilidade.
            </p>
          </div>
          <div className='col-12 col-md-6'>
            <ImgWebp
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/pronampe-dobra2/image.webp'
              alt='empreendedora concentrada usando seu notebook'
              breakPointsSize={
                {
                  sm: '100%',
                  md: '300px',
                  lg: '360px',
                  xl: '468px',
              }
              }
            />
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default OQueEOPronampe
