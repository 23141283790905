import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import * as S from './styles'
import slugify from 'slugify'

interface IPronampeSteps {
  step: number;
  text: string;
}

const pronampeSteps: IPronampeSteps[] = [
  {
    step: 1,
    text: 'Acesse nossa área de Empréstimos no Super App e selecione a opção <span class="fw-700">Pronampe</span>',
  },
  {
    step: 2,
    text: 'Faça sua <span class="fw-700">simulação</span> e entenda as condições previstas para a sua empresa',
  },
  {
    step: 3,
    text: 'Clique em <span class="fw-700">Solicitar Análise de Crédito</span>',
  },
  {
    step: 4,
    text: 'Autorize sua consulta de margem no <span class="fw-700">e-CAC</span> (conte com nosso tutorial, caso necessário!)',
  },
  {
    step: 5,
    text: '<span class="fw-700">Revise</span> sua solicitação e clique em <span class="fw-700">contratar</span>',
  },
  {
    step: 6,
    text: 'Siga os passos para assinar seu contrato de forma <span class="fw-700">digital e segura</span>',
  },
]

const ComoSolicitarOPronampeNoInter = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-28 fs-md-40 lh-md-44 fs-xl-48 lh-lg-52 fw-500 text-grayscale--500 text-center mb-4'>Como solicitar o Pronampe <span className='d-md-block d-lg-inline'>no Inter?</span></h2>
            <h3 className='fs-16 lh-20 fs-md-20 lh-md-25 fs-lg-16 lh-lg-20 fs-xl-24 lh-lg-30 fw-400 text-grayscale--500 font-sora text-center mb-4 pb-2 mb-md-5'>Destravar o futuro do seu negócio pode ser simples!</h3>
            <S.CardsContainer>
              {pronampeSteps.map((step: IPronampeSteps) => (
                <div className='step' key={slugify(step.text)}>
                  <div>
                    <div className='step-number'><span>{step.step}</span></div>
                  </div>
                  <p className='fs-14 lh-17 fs-lg-12 lh-lg-15 fs-xl-16 lh-xl-20 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: step.text }} />
                </div>
              ))}
            </S.CardsContainer>
            <p className='fs-16 lh-20 fs-md-20 lh-md-25 fs-lg-16 lh-lg-20 fs-xl-24 lh-lg-30 fw-400 text-grayscale--500 font-sora text-center mt-4 pt-2 mb-0'>E pronto! Em poucos dias o valor solicitado fica disponível na <span className='d-md-block'>conta da sua empresa.</span></p>
            <div className='d-flex justify-content-center mt-4 pt-2'>
              <a
                href='https://conta-digital-pj.inter.co/login'
                className='btn btn--lg bg-orange--extra text-white text-none text-center mt-0'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    element_action: 'click button',
                    element_name: 'Quero contratar agora',
                    section_name: 'Como solicitar o Pronampe no Inter?',
                    redirect_url: 'https://conta-digital-pj.inter.co/login',
                  })
                }}
              >
                Quero contratar agora
              </a>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default ComoSolicitarOPronampeNoInter
