import React from 'react'
import ScrollTo from 'src/components/ScrollTo'

import BuildingIcon from '@interco/icons/build-v4/orangeds/MD/building'
import StoreIcon from '@interco/icons/build-v4/bidis/Icons/store'

import { IconProps } from 'src/components/Layout/Footer/Footer.interface'
import { WIDTH_MD } from 'src/utils/breakpoints'
import * as S from './styles'

interface ICard {
  icon: string;
  title: string;
  description: string;
}

const QuemTemDireitoAoPronampe = () => {
  const [ isMobile, setIsMobile ] = React.useState(false)

  React.useEffect(() => {
    if (window.innerWidth < WIDTH_MD) {
      setIsMobile(true)
    }
  }, [])

  const iconsList: IconProps = {
    store: <StoreIcon height={24} width={24} color='#161616' />,
    building: <BuildingIcon height={24} width={24} color='#161616' />,
  }

  const cards: ICard[] = [
    {
      icon: 'store',
      title: 'Microempresa',
      description: 'Receita bruta anual <span class="d-md-block">de até R$360 mil</span>',
    },
    {
      icon: 'building',
      title: 'Empresa de <span class="d-md-block">Pequeno Porte</span>',
      description: 'Receita bruta anual entre R$360 mil e R$4,8 milhões',
    },
  ]

  return (
    <S.Section>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 lh-28 fs-md-28 lh-md-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-lg-52 fw-500 text-grayscale--500 mb-3'>Quem tem direito ao Pronampe?</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 mb-4'>
              Sua empresa tem direito ao Pronampe caso se encaixe em <span className='fw-700'>{isMobile ? 'uma das categorias abaixo.' : 'uma das categorias ao lado.'}</span>
              <br /><br />
              Além disso, é necessária a emissão da <span className='fw-700'>Certidão Negativa de Débitos (CND)</span> e a autorização do compartilhamento de dados de faturamento com a Receita Federal via <span className='fw-700'>e-CAC</span> antes da contratação.
            </p>
            <div>
              <ScrollTo
                styles='d-none d-md-flex btn btn--lg bg-orange--extra text-white text-none mw-100'
                to='#como-funciona-o-pronampe-no-inter'
                section='dobra_03'
                sectionName='Quem tem direito ao Pronampe?'
                elementName='Saber mais'
                title='Saber mais'
              >
                Saiba Mais
              </ScrollTo>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-7 offset-lg-1 offset-xl-0'>
            <S.CardsContainer>
              {cards.map((card: ICard) => (
                <div className='pronampe-card' key={card.icon}>
                  <div>
                    {iconsList[card.icon]}
                    <h3 className='fs-16 lh-20 fs-md-14 lh-md-17 fs-lg-16 lh-lg-20 fs-xl-20 lh-xl-26 fw-600 font-sora text-grayscale--500 mt-3 mb-0' dangerouslySetInnerHTML={{ __html: card.title }} />
                  </div>
                  <div>
                    <p className='fs-14 lh-17 fs-md-10 lh-md-12 fs-lg-12 lh-lg-14 fs-xl-14 lh-xl-17 text-grayscale--400 mb-0' dangerouslySetInnerHTML={{ __html: card.description }} />
                  </div>
                </div>
              ))}
            </S.CardsContainer>
            <ScrollTo
              styles='d-block d-md-none btn btn--lg bg-orange--extra text-white text-none'
              to='#como-funciona-o-pronampe-no-inter'
              section='dobra_03'
              sectionName='Quem tem direito ao Pronampe?'
              elementName='Saber mais'
              title='Saber mais'
            >
              Saiba Mais
            </ScrollTo>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default QuemTemDireitoAoPronampe
