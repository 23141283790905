import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${grayscale[100]};
  padding: 3rem 0;

  .btn {
    margin-top: 2rem;

    @media ${device.tablet} {
      width: 216px;
    }

    @media ${device.desktopLG} {
      width: 372px;
    }

    @media ${device.desktopXL} {
      width: 412px;
    }
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @media ${device.tablet} {
    flex-direction: row;
    flex-shrink: 0;
  }

  .pronampe-card {
    display: flex;
    width: 312px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  
    border-radius: 16px;
    border: 2px solid ${grayscale[200]};
    background: white;
  
    @media ${device.tablet} {
      justify-content: space-between;
      width: auto;
      padding: 20px;
      height: 186px;
      gap: 28px;
      flex-shrink: 1;
    }
  
    @media ${device.desktopLG} {
      flex: 1 0 0;
      align-self: stretch;
    }

    @media ${device.desktopXL} {
      height: 204px;
    }
  }
`
