import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { brand } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${brand.claryOrange};
  padding: 3rem 0;

  .btn {
    margin-top: 2rem;
  }

  @media ${device.tablet} {
    height: 390px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/pronampe-hero/image.webp');
    background-repeat: no-repeat;
    background-size: 330px 340px;
    background-position: right 30px bottom;
  }

  @media ${device.desktopLG} {
    height: 520px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/pronampe-hero/image.webp');
    background-repeat: no-repeat;
    background-size: 418px 428px;
    background-position: right 46px bottom;
  }

  @media ${device.desktopXL} {
    height: 728px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/pronampe-hero/image.webp');
    background-repeat: no-repeat;
    background-size: 620px 636px;
    background-position: right 60px bottom;
  }
`
