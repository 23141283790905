import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import {
  Hero,
  OQueEOPronampe,
  QuemTemDireitoAoPronampe,
  ComoFuncionaOPronampeNoInter,
  ComoSolicitarOPronampeNoInter,
} from './sections/_index'

import { Wrapper } from './style'

const AntecipacaoRecebiveis = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <OQueEOPronampe />
        <QuemTemDireitoAoPronampe />
        <ComoFuncionaOPronampeNoInter />
        <ComoSolicitarOPronampeNoInter />
      </Layout>
    </Wrapper>
  )
}

export default AntecipacaoRecebiveis
